<template>
  <div class="app-reg-success" :class="styleState">
    <div class="banner">
      <div class="banner-box">
        <h2 class="title">{{$t('appH5.regSuccessText1')}}</h2>
        <div class="p1-box">
          <p class="p1">{{bannerList.adTitle}}</p>
          <img v-if="styleimg == 'coin'" class="xsj" src="@/assets/images/appH5/coin-xsj.png" alt="">
          <img v-else-if="styleimg == 'trade'" class="xsj" src="@/assets/images/appH5/trade-xsj.png" alt="">
          <img v-else-if="styleimg == 'cm'" class="xsj" src="@/assets/images/appH5/cm-xsj.png" alt="">
          <img v-else class="xsj" src="@/assets/images/appH5/xsj.png" alt="">
        </div>
        <img class="banner-img" :src="bannerList.adImageUrl" alt="">        
      </div> 
      <img class="xx1" src="@/assets/images/appH5/xx.png" alt="">  
      <img class="xx2" src="@/assets/images/appH5/xx.png" alt="">
      <img class="xx3" src="@/assets/images/appH5/xx.png" alt="">
      <img class="xx4" src="@/assets/images/appH5/xx.png" alt="">
      <img class="xx5" src="@/assets/images/appH5/xx.png" alt="">
    </div>
    <div class="content">
      <div class="content-box">
        <!-- <a @click="transactionBtn" class="app-btn app-btn1" href="javascript:;">{{$t('appH5.regSuccessText3')}}</a> -->
        <a @click="depositbtn" class="app-btn app-btn2" href="javascript:;">{{$t('appH5.regSuccessText3')}}</a>
        <a @click="identityBtn" class="app-a-btn" href="javascript:;">{{$t('appH5.regSuccessText4')}} ></a>
      </div>
      
    </div>

  </div>
</template>

<script>

import account from "@/api/account";
import profit from "@/util/profitsdk";

export default {
  name: "appRegisteredSuccessfully",
  components: {
   
  },
  data() {
    return {
      styleState:'',
      styleimg:'',
      bannerList:[]   
    }
  },
  created() {
     
      // 根据马甲包ui颜色改变页面ui颜色
      const appId = this.$route.query.appId;
      switch(appId) {
          case "com.exchange6.app":
          case "com.fnsadw.ios":
              console.log("app-reg-success");             
              break;
          case "com.cmtrade.app.droid":
          case "com.cmtrade.app2.ios":
          case "com.forex.market.investasikan":
          case "com.forex.app03.ios":
          case "com.get.money.investapp.gold":
          case "com.goldinvest.money.foreign":
          case "com.moneymaager.invest.forex":        
              console.log("trade-app-reg-success");
              this.styleState = "trade-app-reg-success";
              this.styleimg = "trade";
              break;
          case "com.tedetppt.app.chysc":
          case "com.zjatsx.nx2s":          
          case "com.mobile.trade.finance.emns": 
          case "com.finance.etoo.invest": 
          case "com.cmtrading.axbk.ios":
              console.log("coin-app-reg-success");
              this.styleState = "coin-app-reg-success";
              this.styleimg = "coin";
              break;
          case "com.stock.market.cm.tw":
              console.log("cm-app-reg-success");
              this.styleState = "cm-app-reg-success";
              this.styleimg = "cm";
              break;            
          default:
              this.styleState = "";
              this.styleimg = "";
      } 
      
      let data = {
        adConfigId : 8,
      }
      account.getAdvertiseByCustomer(data).then((res)=>{
        if(res.data.success == 1){
          this.bannerList = res.data.value[0];
        }else{
          console.log(res.data.message);
        }
      }).catch(err=>{
        console.log(err.message);
      })


  },
  methods: {
    transactionBtn(){
      profit.gotoGuide({
          success(){},
          fail(){}
        })
    },
    depositbtn(){
      if (profit.isClient){
          this.$router.push({ path: '/depositApp' })
          profit.appEvent({eventName:'pf_registesuccessful_deposit'},{
            success(){},
            fail(){}
          })
      }
      // profit.deposit({
      //    success(){}, 
      //    fail(){} 
      //   })
    },
    identityBtn(){
      if (profit.isClient){
          profit.gotoIdentity({
            success(){},
            fail(){}
          })
          profit.appEvent({eventName:'pf_registesuccessful_realname'},{
            success(){},
            fail(){}
          })
      }      
    }
  }
};
</script>
<style>
  @import url("../assets/css/public.css");
</style>
